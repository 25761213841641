@import '@fastdocs/ui-kit/media-queries';

.columns {
  display: grid;
  gap: 2rem;
  margin-bottom: 2rem;

  @media (min-width: $l) {
    grid-template-columns: repeat(4, 1fr);
  }

  > div {
    width: 100%;
    margin: 0;
  }

  &:last-child {
    margin: 0;
  }
}
