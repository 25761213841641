.radio-input-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }

  &.disabled {
    opacity: 0.5;
  }

  > div {
    display: flex;
    flex-wrap: wrap;

    > div {
      display: flex;
      margin-bottom: 1rem;
      margin-right: 2rem;
      margin-left: 0.3rem;

      > label {
        cursor: pointer;
        text-transform: initial;
        font-size: initial;
        font-weight: initial;
        letter-spacing: initial;

        input {
          margin-right: 1rem;
          margin-top: 0.3rem;
        }

        .disabled {
          opacity: 0.5;
        }
      }
    }
  }

  > label {
    margin-bottom: 0.5rem;
  }
}
