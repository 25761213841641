@import '@fastdocs/ui-kit/animations';
.working-hours-form {
  @include fade-in-up;

  h5 {
    font-weight: 400;
    margin-top: 2rem;

    span {
      font-weight: 600;
      color: var(--blue-400);
    }
  }

  .hollidays {
    margin-top: 2rem;

    label {
      margin-bottom: 0.5rem;
    }
  }

  .limited-contract {
    margin-bottom: 2rem;
  }

  main > p:first-child {
    margin-top: 0;
  }

  .form-body > label {
    margin-bottom: 0.5rem;
  }

  .hint {
    margin-top: 2rem;
  }
}
