@import '@fastdocs/ui-kit/media-queries';
@import '@fastdocs/ui-kit/animations';

.container {
  @include fade-in-up;
  display: grid;
  margin: -9.125rem 2rem 6rem 2rem;
  border-radius: 1rem;
  overflow: hidden;

  @media (min-width: $l) {
    grid-template-columns: minmax(min-content, 35rem) 3fr;
    margin-left: 6rem;
    margin-right: 6rem;
    min-height: 61vh;
  }

  @media (min-width: $xxl) {
    margin-left: auto;
    margin-right: auto;
    max-width: 200rem;
  }
}
