@import '@fastdocs/ui-kit/media-queries';

.header {
  display: flex;
  flex-direction: column;
  padding: 7rem 3rem 0 3rem;
  margin-bottom: -2rem;
  background-color: var(--anthracite-100);

  @media (min-width: $m) {
    padding: 6rem 3rem 0 3rem;
    min-height: 25rem;
    margin-bottom: -4rem;
    justify-content: center;
  }

  .contentContainer {
    margin: 3rem 0 4rem 0;

    @media (min-width: $m) {
      --width: 95rem;
      margin-right: auto;
      margin-left: auto;
    }
  }

  .stageHeader {
    h4 {
      font-weight: 400;
      text-align: center;
    }
  }
}
