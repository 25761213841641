@mixin fade-in-up {
	@keyframes fade-in-up {
		0% {
			transform: translateY(0.5rem);
			opacity: 0;
		}
		100% {
			transform: translateX(0);
			opacity: 1;
		}
	}

	opacity: 0;
	animation-name: fade-in-up;
	animation-duration: 200ms;
	animation-delay: 100ms;
	animation-timing-function: ease-in-out;
	animation-fill-mode: forwards;
	animation-direction: normal;
}
