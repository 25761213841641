.status-label {
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  pointer-events: none;
  margin-right: 0.75rem;
  background-color: var(--anthracite-200);
  padding: 0.25rem 0.75rem;
  border-radius: 0.5rem;
  max-width: max-content;
  border: 0.125rem solid var(--anthracite-300);

  span {
    line-height: 1px;
  }

  &.open {
    background-color: var(--yellow-200);
    border-color: var(--yellow-300);
  }

  &.finished {
    background-color: var(--green-200);
    border-color: var(--green-300);
  }

  .indicator {
    line-height: 0;
    margin-right: 0.5rem;

    &.open {
      color: var(--yellow-600);
    }

    &.finished {
      color: var(--green-600);
    }
  }

  &:last-child {
    margin-right: 0;
  }
}
