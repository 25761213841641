.suggest-text-input {
  position: relative;
  display: flex;
  flex-direction: column;

  ul {
    position: absolute;
    list-style: none;
    margin: 0.5rem 0 0 0;
    background: var(--anthracite-100);
    border-radius: 0.5rem;
    border: 0.125rem solid var(--anthracite-200);
    padding: 0;
    font-size: 2rem;
    line-height: normal;
    -webkit-appearance: none;
    transition: all ease 300ms;
    overflow-y: scroll;
    max-height: 15.25rem;
    left: 0;
    top: 4.5rem;
    right: 0;
    z-index: 90;

    li {
      transition: all ease 150ms;
      cursor: pointer;

      &:hover {
        background-color: var(--anthracite-200);
        color: var(--blue-400);

        button {
          color: var(--blue-400);
        }
      }

      &:focus {
        background-color: var(--anthracite-200);
        color: var(--blue-400);
      }

      &:last-child {
        margin-bottom: 0;
      }

      button {
        padding: 1rem 1.25rem;
        width: 100%;
        text-align: left;
        border: none;
        background: none;
        cursor: pointer;
        color: var(--anthracite-700);

        &:focus {
          outline: none;
        }
      }
    }
  }
}
