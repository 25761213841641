input[type='email'] {
  height: 4rem;
  background: var(--anthracite-100);
  border: 1px solid var(--anthracite-200);
  border-radius: 0.25rem;
  padding: 0 1rem;
  font-size: 2rem;
  color: var(--anthracite-700);
  line-height: normal;
  -webkit-appearance: none;
  transition: all ease 300ms;

  &:focus {
    outline: none;
    border-color: var(--blue-400);
  }

  &::placeholder {
    color: var(--anthracite-500);
  }

  &:-ms-input-placeholder {
    color: var(--anthracite-500);
  }
}
