.card {
	background-color: var(--white);
	box-shadow: 0 2px 10px 0 rgba(194, 199, 204, 0.5),
		0 10px 20px 0 rgba(207, 218, 230, 0.5);
	border-radius: 0.5rem;
}

.card-dark {
	background-color: var(--white);
	box-shadow: 0 2px 10px 0 hsla(210, 80%, 15%, 0.5),
		0 10px 20px 0 hsla(211, 80%, 15%, 0.5);
	border-radius: 0.5rem;
}

.box-shadow {
	box-shadow: 0 2px 10px 0 hsla(210, 80%, 15%, 0.5);
}

@mixin box-shadow {
	box-shadow: 0px 4px 8px rgba(38, 57, 90, 0.1),
		0px 8px 20px rgba(38, 58, 90, 0.1);
}
