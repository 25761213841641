.animated-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 2.3rem;

  @keyframes pulse {
    0% {
      background-color: var(--blue-100);
    }
    50% {
      background-color: var(--blue-400);
    }
    100% {
      background-color: var(--blue-100);
    }
  }

  .dot {
    $size: 1rem;

    display: block;
    width: $size;
    height: $size;
    background-color: var(--blue-400);
    border-radius: 10rem;
    margin: 0.25rem;
    animation: pulse ease 1500ms infinite;

    &:nth-child(2) {
      animation-delay: 150ms;
    }

    &:last-child {
      animation-delay: 250ms;
    }
  }
}
