@import '@fastdocs/ui-kit/animations';

.additional-insurance-informations-form {
  @include fade-in-up;

  h5 {
    margin: 2rem 0;
    font-weight: 600;

    &:first-child {
      margin-top: 0;
    }
  }

  p {
    margin: 0;
  }
}
