@import '@fastdocs/ui-kit/animations';
.sallary-form {
  @include fade-in-up;

  .preprend-number-input-field {
    margin-bottom: 2rem;

    label {
      margin-bottom: 0.5rem;
    }

    .hint {
      margin-top: 2rem;
    }
  }
}
