@import '@fastdocs/ui-kit/animations';
.success-form {
  .form-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 6rem;

    h4 {
      font-weight: 400;
    }

    h5 {
      margin-top: 5rem;
      font-weight: 400;

      span {
        font-weight: 600;
      }
    }
  }
  @include fade-in-up;
}
