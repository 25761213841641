.autosuggest-text-input-field {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }

  > label {
    margin-bottom: 0.5rem;
  }

  input[type='text'] {
    padding-right: 4rem;
    text-overflow: ellipsis;

    &::-ms-clear {
      display: none;
    }
  }

  .clear-button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--anthracite-400);
    outline: none;
    border: none;
    border-radius: 40rem;
    height: 2rem;
    width: 2rem;
    min-height: initial;
    bottom: 1.125rem;
    right: 1.125rem;
    cursor: pointer;
    padding: 0;

    svg {
      width: 0.75rem;
      height: 0.75rem;
    }

    &:hover {
      background-color: var(--blue-400);

      svg > g > g {
        stroke: var(--anthracite-100);
      }
    }
  }

  ul {
    position: absolute;
    list-style: none;
    margin: 0;
    background: var(--anthracite-100);
    border-radius: 0.25rem;
    border: 0.125rem solid var(--anthracite-200);
    padding: 0;
    font-size: 2rem;
    line-height: normal;
    -webkit-appearance: none;
    transition: all ease 300ms;
    overflow-y: scroll;
    max-height: 15.25rem;
    left: 0;
    top: 8rem;
    right: 0;
    z-index: 100;

    li {
      transition: all ease 150ms;
      cursor: pointer;

      &:hover {
        background-color: var(--anthracite-200);
        color: var(--blue-400);

        button {
          color: var(--blue-400);
        }
      }

      &:focus {
        background-color: var(--anthracite-200);
        color: var(--blue-400);
      }

      &:last-child {
        margin-bottom: 0;
      }

      button {
        padding: 1rem 1.25rem;
        width: 100%;
        text-align: left;
        border: none;
        background: none;
        cursor: pointer;
        color: var(--anthracite-700);

        &:focus {
          outline: none;
        }
      }

      &.result-hint {
        color: var(--anthracite-700);

        &:hover {
          background: none;
          cursor: default;
        }

        div {
          padding: 1rem 1.25rem;
        }
      }
    }
  }
}
