@import '@fastdocs/ui-kit/animations';

.bankInformationForm {
  @include fade-in-up;

  .validateInfo {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .warn {
      color: var(--yellow-500);
    }
    .error {
      color: var(--red-500);
    }
  }
}
