@import '@fastdocs/ui-kit/media-queries';
@import '@fastdocs/ui-kit/animations';

.job-details-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 30;
  background-color: rgba(242, 244, 247, 0.9);
  overflow-y: scroll;

  &--wrapper {
    padding: 2rem;
    width: 100%;

    @media (min-width: $l) {
      width: auto;
      padding: 6rem;
    }
  }

  &--content {
    display: grid;
    position: relative;
    background-color: var(--white);
    box-shadow: 0 8px 10px 0 rgba(194, 199, 204, 0.5),
      0 16px 20px 0 rgba(207, 218, 230, 0.5);
    border-radius: 1rem;
    width: 100%;
    overflow: hidden;

    @media (min-width: $l) {
      max-width: 120rem;
    }
  }

  &--header {
    display: grid;
    grid-template-columns: 1fr 0fr;
    align-items: center;
    padding: 4rem;
    gap: 2rem;

    > * {
      margin: 0;
    }
  }

  &--status {
    display: grid;
    grid-template-rows: repeat(2, min-content);
    padding: 0 4rem;
    gap: 1rem;

    main {
      display: grid;
      gap: 2rem;

      @media (min-width: $l) {
        grid-template-columns: repeat(3, 25rem);
      }

      label {
        display: flex;
        flex-direction: column;

        > div {
          margin-top: 0.5rem;
        }
      }
    }
  }

  &--details {
    display: grid;
    grid-template-rows: repeat(2, min-content);
    padding: 4rem;
    gap: 1rem;

    header {
      p {
        margin-bottom: 1rem;
      }
    }

    main {
      display: grid;
      grid-template-rows: repeat(3, min-content);

      table {
        width: 100%;
        margin-bottom: 1rem;

        @media (min-width: $l) {
          margin-bottom: 4rem;
        }

        &:last-child {
          margin-bottom: 0;
        }

        tr {
          display: grid;
          margin-bottom: 2rem;
          border-bottom: 1px solid var(--anthracite-200);
          padding-bottom: 1.5rem;

          &:last-child {
            border-bottom: none;
          }

          @media (min-width: $l) {
            display: table-row;
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
          }

          th {
            text-transform: uppercase;
            font-size: 1.5rem;
            font-weight: 600;
            letter-spacing: 0.5px;
            text-align: left;
            margin-bottom: 0.5rem;

            @media (min-width: $l) {
              border-bottom: 1px solid var(--anthracite-200);
              padding: 1.5rem 0;
              margin-bottom: 0;
              width: 26rem;
            }
          }

          td {
            text-align: left;
            cursor: default;
            width: max-content;

            @media (min-width: $l) {
              border-bottom: 1px solid var(--anthracite-200);
              padding-left: 1rem;
            }

            span {
              display: flex;
              align-items: center;

              textarea {
                white-space: nowrap;
                overflow: scroll;
                text-overflow: ellipsis ellipsis;
                max-height: 2.5rem;
                width: 100%;
                max-width: 35rem;
                outline: none;
                appearance: none;
                border: none;
                resize: none;
                -ms-overflow-style: none;
                background: transparent;
                padding: 0;

                &::-webkit-scrollbar {
                  display: none;
                }

                &:focus {
                  outline: none;
                }
              }

              button {
                margin-left: 0.5rem;
              }
            }
          }

          &:last-child {
            th,
            td {
              border: none;
            }
          }
        }
      }
    }
  }

  &--alert {
    @include fade-in-up;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 1);

    main {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      svg {
        stroke: var(--yellow-500);
        margin-bottom: 1rem;
      }

      button {
        margin-top: 1rem;
      }

      h5 {
        font-weight: 400;
        margin-bottom: 2rem;
        max-width: 25ch;
        line-height: 1.2;
      }

      p {
        font-size: 1.5rem;
        color: var(--anthracite-500);
        margin-top: 3rem;
        line-height: 1.2;
      }
    }
  }

  &--edit {
    @include fade-in-up;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 1);

    main {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      svg {
        stroke: var(--anthracite-700);
        margin-bottom: 1rem;
      }

      button {
        margin-top: 1rem;
      }

      h5 {
        font-weight: 400;
        margin-bottom: 2rem;
        max-width: 25ch;
        line-height: 1.2;
      }

      p {
        font-size: 1.5rem;
        color: var(--anthracite-500);
        margin-top: 3rem;
        line-height: 1.2;
      }
    }
  }

  &--options {
    display: grid;
    gap: 1rem;
    padding: 0 4rem 4rem 4rem;
  }
}
