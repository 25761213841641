@import '@fastdocs/ui-kit/media-queries';
@import '@fastdocs/ui-kit/animations';

.integrations-settings-details {
  @include fade-in;
  padding: 3rem;

  header {
    h4 {
      margin-top: 0.25rem;
      font-weight: 400;
    }
    margin-bottom: 5rem;
  }

  main {
    .integration-item {
      display: grid;
      border: 1px solid var(--anthracite-300);
      border-radius: 0.5rem;
      overflow: hidden;

      &__header {
        margin-bottom: 0;
        padding: 3rem 3rem 2rem 3rem;
      }

      &__main {
        padding: 0 3rem 0 3rem;

        p {
          max-width: 50ch;
        }
      }

      &__footer {
        display: grid;
        grid-template-columns: repeat(2, max-content);
        gap: 0.5rem;
        padding: 3rem;
      }

      &__contract-hint {
        background-color: var(--anthracite-100);
        padding: 2rem 3rem;

        p {
          margin: 0 0 1rem 0;
          max-width: 45ch;
        }
      }
    }
  }
}
