.autofillButton {
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 2.5rem;
  z-index: 90;
  background: none;
  border: none;
  color: var(--anthracite-700);

  &:hover {
    color: var(--blue-400);
    cursor: pointer;
  }
}
