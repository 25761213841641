.number-input-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  > input[type='number'] {
    min-width: auto;
    width: auto;
    border-right-width: 1px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  > label {
    margin-bottom: 0.5rem;
  }
}
