@import '@fastdocs/ui-kit/animations';

.capital-accumulation-form {
  @include fade-in-up;

  .preprend-number-input-field {
    margin-bottom: 2rem;

    label {
      margin-bottom: 0.5rem;
    }
  }
}
