@import '@fastdocs/ui-kit/media-queries';
@import '@fastdocs/ui-kit/animations';

.staff-data-view {
  tbody tr td {
    cursor: pointer;
  }

  .job-details-container {
    transition: opacity ease 200ms;
    opacity: 0;
  }

  .job-details-container.fade {
    opacity: 1;
  }

  .job-details-container--content {
    transition: all ease-out 200ms;
    transform: translateY(3%);
    opacity: 0;
  }

  .job-details-container--content.move {
    opacity: 1;
    transform: translateY(0);
  }

  .staff-advertisement {
    @include fade-in-up;
    margin: 0 2rem 6rem 2rem;
    margin-top: -9.125rem;
    border-radius: 1rem;
    overflow: hidden;
    text-align: center;
    padding: 10rem 5rem;

    h5 {
      margin: 2rem auto;
      max-width: 50ch;
      font-weight: normal;
    }

    @media (min-width: $l) {
      margin-left: 6rem;
      margin-right: 6rem;
    }

    @media (min-width: $xxl) {
      margin-left: auto;
      margin-right: auto;
      max-width: 200rem;
    }
  }
}
