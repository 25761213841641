.date-input-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }

  > label {
    margin-bottom: 0.5rem;
  }
}
