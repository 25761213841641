.radioGroupContainer {
  margin-bottom: 2rem;

  label {
    margin-bottom: 0.5rem;
  }

  .radioGroup {
    border-radius: 0.5rem;
    border: 1px solid var(--anthracite-200);
    transition: all ease 300ms;

    &:hover {
      border-color: var(--anthracite-400);
    }

    label {
      padding: 1.5rem;
      margin: 0 0 0 1rem;
      border-bottom: 1px solid var(--anthracite-200);
      font-size: initial;
      font-weight: initial;
      text-transform: initial;
      letter-spacing: initial;
      cursor: pointer;
      transition: all ease 200ms;

      &:last-child {
        border-bottom: 0;
      }

      .inputContainer {
        display: flex;
        align-items: center;

        input {
          margin-right: 1.5rem;
        }
      }

      .description {
        display: grid;

        span {
          font-size: 1.75rem;
          color: var(--anthracite-600);
        }
      }
    }
  }
}
