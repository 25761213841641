@import '@fastdocs/ui-kit/media-queries';
@import '@fastdocs/ui-kit/animations';

.form-options-details {
  @include fade-in;
  padding: 3rem;

  header {
    h4 {
      margin-top: 0.25rem;
      font-weight: 400;
    }
    p {
      margin-top: 1rem;
    }
    margin-bottom: 5rem;
  }

  main {
    section {
      margin-bottom: 3rem;
      padding-bottom: 3rem;
      border-bottom: 1px solid var(--anthracite-300);

      &:last-child {
        margin-bottom: 0;
        border-bottom: 0;
      }

      form {
        display: grid;
        gap: 1rem;

        @media (min-width: $m) {
          max-width: 50rem;
          grid-template-columns: 2fr 1fr;
        }
      }

      label {
        text-transform: uppercase;
        font-size: 1.5rem;
        font-weight: 600;
        letter-spacing: 0.5px;
        margin-bottom: 0.5rem;
      }

      .input-container {
        display: flex;

        .saved-hint {
          color: var(--anthracite-400);
          align-self: flex-end;
          margin-bottom: 0.25rem;
        }

        .radio-input-field {
          margin-bottom: 0;

          div {
            @media (min-width: $s) {
              margin-bottom: 0;
            }

            label {
              text-transform: initial;
              font-size: initial;
              font-weight: initial;
              letter-spacing: initial;
              margin-bottom: initial;
            }
          }
        }
      }

      p {
        margin-top: 0rem;
        max-width: 60ch;
      }

      small {
        display: block;
        margin-top: 1.5rem;
        color: var(--anthracite-600);
        max-width: 65ch;
        font-size: 1.5rem;
        line-height: 1.2;
      }
    }
  }
}
