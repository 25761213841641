@import '@fastdocs/ui-kit/media-queries';
@import '@fastdocs/ui-kit/animations';

.settings-details {
  @include fade-in;
  padding: 3rem;

  header {
    h4 {
      margin-top: 0.25rem;
      font-weight: 400;
    }
    margin-bottom: 5rem;
  }

  main {
    section {
      margin-bottom: 3rem;
      padding-bottom: 3rem;
      border-bottom: 1px solid var(--anthracite-300);

      &:last-child {
        margin-bottom: 0;
        border-bottom: 0;
      }

      .information {
        display: grid;
        gap: 0.5rem;

        p {
          margin-top: 0;
        }
      }

      > * {
        max-width: 70rem;
      }
    }
  }
}
