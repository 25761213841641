@import '@fastdocs/ui-kit/media-queries';

.columns-1-1 {
  display: grid;
  gap: 2rem;
  margin-bottom: 2rem;

  @media (min-width: $l) {
    grid-template-columns: repeat(2, 1fr);
  }

  > div {
    width: 100%;
    margin: 0;
  }

  &:last-child {
    margin: 0;
  }
}

.columns-3-5 {
  display: grid;
  gap: 2rem;
  margin-bottom: 2rem;

  @media (min-width: $l) {
    grid-template-columns: 3fr 5fr;
  }

  > div {
    width: 100%;
    margin: 0;
  }

  &:last-child {
    margin: 0;
  }
}

.columns-5-3 {
  display: grid;
  gap: 2rem;
  margin-bottom: 2rem;

  @media (min-width: $l) {
    grid-template-columns: 5fr 3fr;
  }

  > div {
    width: 100%;
    margin: 0;
  }

  &:last-child {
    margin: 0;
  }
}
