@import '@fastdocs/ui-kit/media-queries';
@import '@fastdocs/ui-kit/animations';

.table {
  @include fade-in-up;
  margin: 0 2rem;
  padding-bottom: 6rem;

  @media (min-width: $l) {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  @media (min-width: $xxl) {
    margin-left: auto;
    margin-right: auto;
    max-width: 200rem;
  }

  .table-container {
    border-radius: 1rem;
    overflow: hidden;

    .actions-bar {
      display: flex;

      .search {
        flex-grow: 1;
        display: flex;

        .icon {
          padding: 2rem;
          color: var(--anthracite-500);
        }

        input {
          flex-grow: 1;
          padding: 1rem 0;
          border: none;
          border-radius: 0;
          background-color: var(--white);
          box-shadow: none;
        }

        .reset {
          display: flex;
          align-self: center;
          padding: 0.5rem;
          border-radius: 10rem;
          margin-right: 1.5rem;

          svg {
            width: 1rem;
            height: 1rem;
          }
        }
      }

      .actions {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem 1.5rem;
        border-left: 1px solid var(--anthracite-400);
      }
    }

    .table-content {
      overflow: scroll;
      max-height: calc(100vh - 33rem);

      table {
        width: 100%;
      }

      thead {
        th {
          border-top: 1px solid var(--anthracite-400);
          border-bottom: 1px solid var(--anthracite-400);
          background-color: var(--anthracite-100);
          padding: 1.125rem 1.75rem;
          text-align: left;
          text-transform: uppercase;
          font-size: 1.5rem;
          font-weight: 600;
          letter-spacing: 0.5px;
          vertical-align: top;
          white-space: nowrap;
          position: sticky;
          top: 0;
          z-index: 10;
        }
      }

      tbody {
        tr {
          transition: all ease-out 150ms;

          &:last-child {
            td {
              border: none;
            }
          }

          &:hover {
            background-color: var(--anthracite-100);
          }

          &.empty {
            &:hover {
              background-color: transparent;
            }

            td {
              text-align: center;
              cursor: default;
              color: var(--anthracite-500);
              padding: 4.5rem;

              svg {
                margin: auto;
                display: block;
                margin-bottom: 1rem;
              }
            }
          }

          td {
            @include fade-in;
            border-bottom: 1px solid var(--anthracite-200);
            text-align: left;
            padding: 1.75rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
