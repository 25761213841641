.browser-error-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15rem;
  text-align: center;
  padding: 0 2rem;

  figure {
    max-width: 60rem;
    margin-bottom: 5rem;
  }

  aside {
    max-width: 70rem;

    h1 {
      font-weight: 400;
      color: var(--blue-400);
    }

    p {
      font-size: 2rem;
      max-width: 50rem;
    }
  }
}
