@import '@fastdocs/ui-kit/animations';
@import '@fastdocs/ui-kit/media-queries';
@import '@fastdocs/ui-kit/utilities';

.formularSelector {
  @include fade-in-up;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(34rem, 1fr));
  grid-auto-rows: 1fr;
  gap: 2rem;
  position: relative;
  z-index: 20;
  max-width: 160rem;
  margin: auto;
  padding: 0 2rem 10rem 2rem;

  .formularLink {
    display: inline-flex;
    flex-grow: 1;
    flex-basis: 50%;
    outline: none;
    color: var(--anthracite-700);
    transition: all ease 300ms;
    text-decoration: none;
    border: solid 0.125rem var(--anthracite-200);
    border-radius: 0.5rem;
    overflow: hidden;
    font-weight: 400;

    &:hover {
      transform: translateY(-0.5rem);
      border-color: transparent;
      @include box-shadow;

      h4 {
        color: var(--blue-400);
      }

      p {
        color: var(--anthracite-700);
      }
    }

    &:focus {
      transform: translateY(-0.5rem);
      border-color: transparent;
      @include box-shadow;

      h4 {
        color: var(--blue-400);
      }

      p {
        color: var(--anthracite-700);
      }
    }

    .formularLinkContent {
      position: relative;
      padding: 4rem;
      width: 100%;
      height: auto;
      box-shadow: none;
      background-color: var(--white);

      svg {
        margin-bottom: 2rem;
        color: var(--blue-400);
      }

      h4 {
        font-weight: 600;
      }
    }
  }
}
