@import '@fastdocs/ui-kit/media-queries';

.form-container {
  position: relative;
  z-index: 1;
  margin: -7rem 2rem 20rem 2rem;

  @media (min-width: $l) {
    margin-left: auto;
    margin-right: auto;
    max-width: 81rem;
  }
}
