@import '@fastdocs/ui-kit/animations';
.pension-insurance-form {
  @include fade-in-up;

  .checkbox-field {
    margin-bottom: 2rem;
  }

  .field {
    @include fade-in;
  }
}
