@import '@fastdocs/ui-kit/animations';
@import '@fastdocs/ui-kit/media-queries';

.summary-form {
  @include fade-in-up;

  ul {
    padding: 0;
    margin: 0 0 3rem 0;
    border-radius: 0.25rem;
    list-style: none;

    &:last-child {
      margin-bottom: 0;
    }

    li {
      display: grid;
      padding: 0.75rem 0;
      border-bottom: 1px solid var(--anthracite-300);

      @media (min-width: $m) {
        gap: 4rem;
        grid-template-columns: 1fr 1fr;
        align-items: center;
      }

      span {
        &:last-child {
          font-weight: 600;
        }
      }

      b {
        color: var(--blue-400);
        font-weight: 600;
      }

      &.other-informations {
        display: block;

        .other-information-container {
          display: block;
          margin-top: 0.5rem;
          max-height: 21rem;
          overflow-y: scroll;

          span {
            font-style: italic;
            word-wrap: break-word;
            display: block;
            white-space: pre-line;
          }
        }
      }
    }

    ul {
      margin-bottom: 0;

      b {
        font-weight: 400;
        color: var(--anthracite-700);
      }
    }
  }
}
