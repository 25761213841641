@import '@fastdocs/ui-kit/animations';
@import '@fastdocs/ui-kit/media-queries';

.AppHeader {
  @include fade-in;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  background: var(--white);
  box-shadow: 0 2px 10px 0 rgba(194, 199, 204, 0.5),
    0 10px 20px 0 rgba(207, 218, 230, 0.5);

  .notificationBanner {
    position: relative;
    display: flex;
    align-items: flex-start;
    color: var(--white);
    background-color: var(--anthracite-700);
    padding: 2rem;
    line-height: 1.2;
    gap: 2rem;

    @media (min-width: $l) {
      padding: 1rem;
      justify-content: center;
      align-items: center;
    }

    .bannerContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: flex-start;
      gap: 2rem;

      @media (min-width: $l) {
        flex-direction: row;
        align-items: center;
      }

      .pill {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        color: var(--anthracite-700);
        background-color: var(--yellow-300);
        border-radius: 10rem;
        padding: 0.25rem 1rem;
        font-size: 1.5rem;
        font-weight: 600;
        text-transform: uppercase;
      }
    }

    .notificationButton {
      right: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      color: var(--white);
      background-color: transparent;
      text-decoration: underline;
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }
    }
  }

  nav {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    padding: 1.5rem;

    .logo {
      align-self: center;
      justify-self: center;
      max-height: 4rem;

      img {
        max-height: 4rem;
        min-height: 3rem;
      }
    }

    > * {
      &:first-child {
        justify-self: start;
      }

      &:last-child {
        justify-self: end;
      }
    }
  }
}
