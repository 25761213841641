@import '@fastdocs/ui-kit/animations';

.hint {
  background-color: var(--yellow-100);
  border: 0.125rem solid var(--yellow-300);
  border-radius: 0.5rem;
  padding: 3rem;
  margin-bottom: 2rem;
  @include fade-in;

  hr {
    border-color: var(--blue-700);
  }

  ul {
    margin: 2rem 0 0 0;
    padding: 0 0 0 2.5rem;

    li {
      line-height: 3rem;
    }
  }

  p:first-child {
    margin-top: 0;
  }
}
