@import '@fastdocs/ui-kit';

html,
body {
  overflow-x: hidden;
}

/* prettier-ignore */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: 
    local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
    url('./static/fonts/source-sans-pro-v11-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('./static/fonts/source-sans-pro-v11-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('./static/fonts/source-sans-pro-v11-latin-regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

/* prettier-ignore */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: 
    local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
    url('./static/fonts/source-sans-pro-v11-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('./static/fonts/source-sans-pro-v11-latin-600.woff') format('woff'), /* Modern Browsers */
    url('./static/fonts/source-sans-pro-v11-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
}

/* prettier-ignore */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: 
    local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
    url('./static/fonts/source-sans-pro-v11-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./static/fonts/source-sans-pro-v11-latin-700.woff') format('woff'), /* Modern Browsers */
    url('./static/fonts/source-sans-pro-v11-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
}
