$base-line-height: 72px;
$spin-duration: 1s;

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-view {
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    position: absolute;
  }
}

.loading {
  border-radius: 50%;
  width: $base-line-height;
  height: $base-line-height;
  border: 0.25rem solid var(--anthracite-200);
  border-top-color: var(--blue-400);
  animation: spin $spin-duration ease infinite;
}

.loading-small {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  border: 0.25rem solid var(--anthracite-200);
  border-top-color: var(--blue-400);
  animation: spin $spin-duration ease infinite;
}

.loading-mini {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  border: 0.25rem solid var(--anthracite-200);
  border-top-color: var(--blue-400);
  animation: spin $spin-duration ease infinite;
}
