@import '@fastdocs/ui-kit/media-queries';

.singleInputFormField {
  display: grid;
  gap: 0.5rem;

  form {
    display: grid;
    gap: 1rem;

    @media (min-width: $xl) {
      grid-template-columns: 5fr 1.5fr;
    }
  }

  small {
    max-width: 74ch;
  }
}
