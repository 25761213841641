.form-footer {
  button {
    background-color: var(--white);
    border: none;
    border-radius: 0 0 0.5rem 0.5rem;
    font-size: 2rem;
    color: var(--blue-400);
    height: 6rem;
    padding: 0;
    width: 100%;
    border-top: 1px solid var(--anthracite-200);
    outline: none;
    cursor: pointer;
    transition: all ease 300ms;

    &:focus {
      border-color: var(--blue-400);
      background-color: var(--blue-400);
      color: var(--white);
    }

    &:hover {
      border-color: var(--blue-400);
      background-color: var(--blue-400);
      color: var(--white);
    }
  }

  &.two-buttons {
    display: flex;

    button {
      &:first-child {
        border-bottom-right-radius: 0;
        color: var(--anthracite-700);
        border-right: 1px solid var(--anthracite-200);

        &:hover {
          color: var(--white);
          border-right-color: var(--blue-400);
        }

        &:focus {
          color: var(--white);
          border-right-color: var(--blue-400);
        }
      }

      &:last-child {
        border-bottom-left-radius: 0;
      }
    }
  }
}
