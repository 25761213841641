.prepend-number-input {
  position: relative;
  display: flex;

  .prepend-field {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    min-width: 5rem;
    top: 1px;
    left: 1px;
    bottom: 1px;
    z-index: 90;
    background: var(--anthracite-200);
    border-radius: calc(0.5rem - 1px) 0 0 calc(0.5rem - 1px);
  }

  input[type='number'] {
    position: relative;
    width: 100%;
    min-width: 5rem;
    padding-left: 6rem;
  }
}
