@import '@fastdocs/ui-kit/media-queries';

nav.dashboard-sub-navigation {
  border-bottom: 1px solid var(--anthracite-200);

  @media (min-width: $l) {
    border-right: 1px solid var(--anthracite-200);
    border-bottom: 0;
  }

  ul {
    padding: 1.5rem 1.5rem 0 1.5rem;
    margin: 0;
    list-style: none;

    &:last-child {
      margin-bottom: 1.5rem;
    }

    @media (min-width: $l) {
      padding: 3rem 3rem 0 3rem;

      &:last-child {
        margin-bottom: 3rem;
      }
    }

    label {
      text-transform: uppercase;
      font-size: 1.5rem;
      font-weight: 600;
      letter-spacing: 0.5px;
      padding-left: 1rem;
      margin-bottom: 0.5rem;
    }

    li {
      margin-bottom: 0.5rem;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 1rem;
        background-color: var(white);
        border-radius: 0.75rem;
        text-decoration: none;

        &:hover {
          color: var(--blue-400);
        }

        &.active {
          color: var(--blue-400);
          background-color: var(--anthracite-100);
        }

        svg {
          margin-right: 1rem;
        }
      }
    }
  }
}
