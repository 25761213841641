@import '@fastdocs/ui-kit/media-queries';
@import '@fastdocs/ui-kit/animations';

.dashboardNavigation {
  @include fade-in;
  display: flex;
  padding: 10rem 2rem 0 2rem;
  background-color: var(--anthracite-100);
  min-height: 15rem;
  margin-bottom: -8.5rem;

  @media (min-width: $l) {
    padding: 14rem 6rem 0 6rem;
  }

  @media (min-width: $xxl) {
    padding: 14rem 0 0 0;
  }

  .linkContainer {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    height: min-content;
    overflow-x: scroll;

    @media (min-width: $xxl) {
      margin-left: auto;
      margin-right: auto;
      min-width: 200rem;
      max-width: 200rem;
    }

    ul {
      position: relative;
      display: flex;
      list-style: none;
      padding: 0;
      height: min-content;

      li {
        display: block;
        margin: 0 0.25rem;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        a {
          display: inline-flex;
          align-items: center;
          padding: 1rem 1.25rem;
          text-decoration: none;
          color: var(--anthracite-600);
          white-space: nowrap;
          border-radius: 0.5rem;
          transition: all ease-out 200ms;

          svg {
            margin-right: 1rem;
          }

          &.active {
            background-color: var(--anthracite-200);
            color: var(--blue-400);

            &:hover {
              background-color: var(--anthracite-200);
              color: var(--blue-400);
            }
          }

          &:hover {
            background-color: var(--anthracite-200);
            color: var(--blue-400);
          }
        }
      }
    }
  }
}
