@import '@fastdocs/ui-kit/media-queries';

.help-over-base {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 45rem;
  max-width: 80%;
  background-color: var(--white);
  z-index: 20;
  box-shadow: -2px 0 10px 0 rgba(194, 199, 204, 0.5),
    -10px 0 20px 0 rgba(207, 218, 230, 0.5);
  transform: translateX(105%);
  transition: transform cubic-bezier(0.8, 0, 0, 0.8) 300ms;

  &.show {
    transform: translateX(0);
  }

  header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    position: relative;
    padding: 0 1rem 0 1.5rem;
    height: 7rem;
    color: var(--blue-400);
    box-shadow: 0 2px 10px 0 rgba(194, 199, 204, 0.5),
      0 10px 20px 0 rgba(207, 218, 230, 0.5);
    text-align: center;

    @media (min-width: $s) {
      padding: 0 1rem 0 3.5rem;
    }

    h5 {
      font-weight: 400;
    }
  }

  main {
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 4rem 0;
    top: 7rem;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: scroll;

    article {
      width: 100%;

      a {
        color: var(--anthracite-700);
        border-bottom: 1px solid var(--blue-400);
        text-decoration: none;

        &:hover {
          color: var(--blue-400);
        }
      }

      .empty-hint {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border: 1px solid var(--anthracite-200);
        margin: 0 4rem;
        padding: 4rem;

        h5 {
          font-weight: 400;
        }
      }

      .bug-hint {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 6rem;

        a {
          color: var(--anthracite-400);
          font-weight: 400;
          border: none;

          &:hover {
            color: var(--blue-400);
          }
        }
      }
    }
  }
}
