@import '@fastdocs/ui-kit/media-queries';

.accordion {
  margin-bottom: 0;
  border-bottom: 1px solid var(--anthracite-200);

  &:first-child {
    border-top: 1px solid var(--anthracite-200);
  }

  .content {
    max-height: 0;
    overflow: hidden;
    transition: all ease 300ms;

    &.show {
      max-height: 90rem;
      padding-bottom: 2rem;
    }

    p {
      &:first-child {
        margin-top: 0;
      }

      .example {
        color: var(--blue-400);
      }
    }
  }

  .icon {
    display: block;
    width: 1.5rem;
    min-width: 1.5rem;
    height: 3rem;
    transition: all ease 300ms;
    margin-left: 2rem;

    &.show {
      transform: rotate(180deg);
    }
  }

  .headline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    padding: 2rem 2rem;
    cursor: pointer;

    @media (min-width: $s) {
      padding: 2rem 4rem;
    }

    &:hover {
      color: var(--blue-400);
    }

    &.show {
      color: var(--blue-400);
    }
  }

  p {
    padding-left: 2rem;
    padding-right: 2rem;

    @media (min-width: $s) {
      padding-left: 4rem;
      padding-right: 4rem;
    }
  }
}
