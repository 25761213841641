@import '@fastdocs/ui-kit/animations';

.notification-card {
  @include fade-in-up;
  animation-delay: 1.5s;
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 50rem;
  z-index: 90;
  margin: 4rem;
  padding: 2rem;

  figure {
    width: 1rem;
    margin-right: 2rem;
    background-color: var(--yellow-400);
    padding: 1.25rem;
    border-radius: 0.5rem;
    flex-shrink: 0;
    width: 2rem;
    height: 2rem;

    svg {
      path {
        stroke: var(--white);
      }

      circle {
        fill: var(--white);
      }
    }
  }

  p {
    margin: 0;
  }

  button {
    display: flex;
    position: absolute;
    right: -1rem;
    top: -1rem;
    margin: 0;
    min-height: initial;
    height: 3rem;
    width: 3rem;
    padding: 1rem;
    border: none;
    border-radius: 5rem;
    transition: none;
    background-color: var(--white);
    cursor: pointer;

    &:hover {
      background-color: var(--blue-400);

      svg {
        g {
          stroke: var(--white);
        }
      }
    }
  }
}
