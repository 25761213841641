@import '@fastdocs/ui-kit/animations';

.bank-informations-form {
  @include fade-in-up;

  .field {
    @include fade-in;
  }

  .error {
    color: var(--yellow-500);
  }

  label {
    margin-bottom: 0.5rem;
  }

  main > p:first-child {
    margin-top: 0;
  }
}
