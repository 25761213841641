@import "./variables";

:root {
	// UI-Utility
	--ui-overlay: #{$overlay};

	--white: #{$white};

	--gray-200: #{$gray-200};
	--gray-300: #{$gray-300};
	--gray-400: #{$gray-400};
	--gray-500: #{$gray-500};
	--gray-600: #{$gray-600};

	--black: #{$black};

	--blue-50: #{$blue-50};
	--blue-100: #{$blue-100};
	--blue-200: #{$blue-200};
	--blue-300: #{$blue-300};
	--blue-400: #{$blue-400};
	--blue-500: #{$blue-500};
	--blue-600: #{$blue-600};
	--blue-700: #{$blue-700};

	--anthracite-50: #{$anthracite-50};
	--anthracite-100: #{$anthracite-100};
	--anthracite-200: #{$anthracite-200};
	--anthracite-300: #{$anthracite-300};
	--anthracite-400: #{$anthracite-400};
	--anthracite-500: #{$anthracite-500};
	--anthracite-600: #{$anthracite-600};
	--anthracite-700: #{$anthracite-700};

	--green-100: #{$green-100};
	--green-200: #{$green-200};
	--green-300: #{$green-300};
	--green-400: #{$green-400};
	--green-500: #{$green-500};
	--green-600: #{$green-600};
	--green-700: #{$green-700};

	--yellow-100: #{$yellow-100};
	--yellow-200: #{$yellow-200};
	--yellow-300: #{$yellow-300};
	--yellow-400: #{$yellow-400};
	--yellow-500: #{$yellow-500};
	--yellow-600: #{$yellow-600};
	--yellow-700: #{$yellow-700};

	--red-100: #{$red-100};
	--red-200: #{$red-200};
	--red-300: #{$red-300};
	--red-400: #{$red-400};
	--red-500: #{$red-500};
	--red-600: #{$red-600};
	--red-700: #{$red-700};
}
