.option-field {
  --transition: all ease 200ms;

  padding: 2rem;
  border: 0.125rem solid var(--anthracite-300);
  border-radius: 0.5rem;
  font-size: initial;
  text-transform: initial;
  letter-spacing: initial;
  font-weight: initial;
  cursor: pointer;
  transition: var(--transition);
  user-select: none;
  margin-top: 1rem;

  &:hover {
    border-color: var(--anthracite-500);
    background-color: var(--anthracite-50);

    .option-field-container .checkmark {
      color: var(--anthracite-500);
    }
  }

  .option-field-container {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;

    input {
      opacity: 0;
    }

    .checkmark {
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      position: absolute;
      width: 2.25rem;
      height: 2.25rem;
      border: 0.125rem solid var(--anthracite-300);
      border-radius: 10rem;
      color: var(--white);
      transition: var(--transition);

      &.active {
        color: var(--white);
        border-color: var(--blue-400);
        background-color: var(--blue-400);
      }
    }
  }

  span {
    margin-left: 2.25rem;
  }

  &.active {
    border-color: var(--blue-400);
    background-color: var(--blue-50);
  }
}
