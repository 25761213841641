@import '@fastdocs/ui-kit/media-queries';
@import '@fastdocs/ui-kit/animations';

.clerk-details-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 30;
  background-color: rgba(242, 244, 247, 0.9);
  overflow-y: scroll;

  &--wrapper {
    padding: 2rem;
    width: 100%;

    @media (min-width: $l) {
      width: auto;
      min-width: 60rem;
      padding: 6rem;
    }
  }

  &--content {
    display: grid;
    position: relative;
    background-color: var(--white);
    box-shadow: 0 8px 10px 0 rgba(194, 199, 204, 0.5),
      0 16px 20px 0 rgba(207, 218, 230, 0.5);
    border-radius: 1rem;
    width: 100%;
    overflow: hidden;

    @media (min-width: $l) {
      max-width: 120rem;
    }
  }

  &--header {
    display: grid;
    grid-template-columns: 1fr 0fr;
    align-items: center;
    padding: 4rem;
    gap: 2rem;

    > * {
      margin: 0;
    }
  }

  &--details {
    display: grid;
    grid-template-rows: repeat(2, min-content);
    padding: 0 4rem 0 4rem;
    gap: 1rem;

    header {
      p {
        margin-top: 0rem;
      }
    }

    main {
      display: grid;
    }
  }

  &--alert {
    @include fade-in-up;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 1);

    main {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      svg {
        stroke: var(--yellow-500);
        margin-bottom: 1rem;
      }

      button {
        margin-top: 1rem;
      }

      h5 {
        font-weight: 400;
        margin-bottom: 2rem;
        max-width: 25ch;
        line-height: 1.2;
      }

      p {
        font-size: 1.5rem;
        color: var(--anthracite-500);
        margin-top: 3rem;
        line-height: 1.2;
      }
    }
  }

  &--options {
    display: grid;
    gap: 1rem;
    padding: 2rem 4rem 4rem 4rem;
  }
}
