@import '@fastdocs/ui-kit/animations';
@import '@fastdocs/ui-kit/media-queries';
@import '@fastdocs/ui-kit/utilities';

.formSelection {
  @include fade-in;

  .stageHeadline {
    margin: auto;
  }
}
