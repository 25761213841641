@import '@fastdocs/ui-kit/animations';
@import '@fastdocs/ui-kit/media-queries';

.consultant-data-form {
  @include fade-in-up;
  max-width: 85rem;

  @media (min-width: $m) {
    .form-header {
      padding: 4rem;
    }

    .form-body {
      padding: 0 4rem 4rem 4rem;
    }

    .form-footer button {
      border-bottom-left-radius: 0;
    }
  }

  .form-content {
    display: grid;

    @media (min-width: $m) {
      grid-template-columns: 2fr 3fr;
    }

    .client-information {
      box-shadow: inset 0 -1px 0 var(--anthracite-200);

      @media (min-width: $m) {
        box-shadow: inset -1px 0 0 var(--anthracite-200);
      }

      .meta-container {
        margin-top: 2rem;

        &:first-child {
          margin-top: 0;
        }

        label {
          font-size: 1.25rem;
          font-weight: bold;
          letter-spacing: 0.02rem;
          text-transform: uppercase;
        }

        p {
          margin: 0;
          hyphens: auto;
        }
      }
    }
  }

  .form-fields {
    display: grid;

    .form-footer {
      align-self: end;
    }
  }
}
