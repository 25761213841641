@import '@fastdocs/ui-kit/animations';
@import '@fastdocs/ui-kit/media-queries';

.formContainer {
  margin-top: 0;
}

.FormStatusView {
  @include fade-in-up;
  margin-top: 0;

  main {
    display: flex;
    flex-direction: column;
    padding: 3rem;

    @media (min-width: $m) {
      padding: 6rem;
    }

    h5 {
      margin-bottom: 2rem;
    }
  }

  .progressSection {
    display: grid;
    grid-template-columns: 1fr 0fr;

    label:last-child {
      justify-content: flex-end;
    }
  }

  .statusTable {
    list-style: none;
    padding: 0;
    margin: 3rem 0;

    li {
      display: grid;
      grid-template-columns: 1fr 0fr;
      padding: 1rem 0.5rem;
      border-bottom: 0.125rem solid var(--anthracite-300);
      align-items: center;

      &:last-child {
        border: none;
      }

      svg {
        color: var(--anthracite-500);
      }
    }
  }
}
