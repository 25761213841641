@import '@fastdocs/ui-kit/media-queries';

.helper {
  position: absolute;
  right: -1rem;
  top: 5.5rem;

  @media (min-width: $l) {
    right: -2.5rem;
    top: 5.5rem;
  }

  .helper-button {
    $width: 5rem;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    color: var(--blue-400);
    border: none;
    border-radius: 10rem;
    width: $width;
    height: $width;
    box-shadow: 0 2px 10px 0 rgba(194, 199, 204, 0.5),
      0 10px 20px 0 rgba(207, 218, 230, 0.5);
    cursor: pointer;
    transition: all ease 200ms;
    outline: none;
    font-size: 2.25rem;
    padding: 0;

    &:hover {
      background-color: var(--blue-400);
      color: var(--white);
    }

    &:focus {
      background-color: var(--blue-400);
      color: var(--white);
    }
  }
}
