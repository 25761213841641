@import '@fastdocs/ui-kit/media-queries';

.form-body {
  display: block;
  padding: 0 3rem 6rem 3rem;

  @media (min-width: $l) {
    padding: 3rem 6rem 6rem 6rem;
  }
}
