@import '@fastdocs/ui-kit/animations';

.additionalShareholdersForm {
  @include fade-in-up;

  .validateInfo {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .warn {
      color: var(--yellow-500);
    }
    .error {
      color: var(--red-500);
    }
  }

  .shareholderHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;

    h5 {
      font-weight: 400;
    }
  }

  .deviderButton {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 4rem 0;

    .devider {
      height: 0.125rem;
      background-color: var(--anthracite-400);
      flex-grow: 1;
    }

    button {
      flex-shrink: 0;
      padding: 0.5rem 1.25rem;
      font-size: 1.75rem;
      z-index: 10;
      margin-right: 2rem;
    }
  }
}
