@import '@fastdocs/ui-kit/animations';

.summaryForm {
  @include fade-in-up;

  .summaryList {
    display: grid;
    gap: 4rem;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      line-height: 3rem;

      b {
        color: var(--blue-400);
        font-weight: 600;
      }
    }

    ul {
      margin-top: 1rem;
      margin-bottom: 1rem;

      b {
        color: var(--anthracite-700);
      }
    }
  }
}
