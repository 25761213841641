@import '@fastdocs/ui-kit/animations';

.five-f-software {
  @include fade-in;
  background-image: url('../../../static/images/foyer-entry.png');
  background-position: center center;
  background-size: cover;
  height: 100vh;
  background-color: var(--blue-600);
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  .content {
    @include fade-in-up;
    max-width: 42rem;
    width: 100%;
    padding: 5rem;

    header {
      margin-bottom: 5rem;
    }

    main {
      h5 {
        font-weight: 400;
        margin-bottom: 2rem;
      }

      p {
        margin-top: 1rem;
      }
    }

    footer {
      margin-top: 3rem;

      a {
        display: block;
        text-align: center;
        margin-top: 2rem;
      }
    }
  }
}
