@import '@fastdocs/ui-kit/animations';
@import '@fastdocs/ui-kit/media-queries';

.insurance-informations-form {
  @include fade-in-up;

  .field {
    @include fade-in;
  }

  .error {
    color: var(--red-500);
  }

  .warn {
    color: var(--yellow-500);
  }

  .validation-info {
    display: flex;
    flex-direction: column;

    @media (min-width: $m) {
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
    }
  }
}
