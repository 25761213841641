.select-box {
  position: relative;

  .icon-container {
    position: absolute;
    right: 0;
    margin-right: 1.5rem;
    top: 30%;
    width: 1.5rem;
    height: 1.5rem;
    pointer-events: none;
  }
}
