@import '@fastdocs/ui-kit/media-queries';
@import '@fastdocs/ui-kit/animations';

.customizing-settings-details {
  @include fade-in;
  padding: 3rem;

  header {
    h4 {
      margin-top: 0.25rem;
      font-weight: 400;
    }
    margin-bottom: 5rem;
  }

  main {
    section {
      margin-bottom: 3rem;
      padding-bottom: 3rem;
      border-bottom: 1px solid var(--anthracite-300);

      &:last-child {
        margin-bottom: 0;
        border-bottom: 0;
      }

      .logo {
        display: grid;
        gap: 1rem;

        @media (min-width: $l) {
          grid-template-columns: 1fr 0fr 0fr;
        }

        .logo-preview {
          @include fade-in;
          display: flex;
          justify-content: center;
          padding: 1rem;
          border-radius: 4px;
          border: 1px solid var(--anthracite-300);
          max-height: 2.5rem;
          color: var(--anthracite-300);
          cursor: default;
        }
      }

      .label {
        text-transform: uppercase;
        font-size: 1.5rem;
        font-weight: 600;
        letter-spacing: 0.5px;
        margin-bottom: 0.5rem;
      }

      small {
        display: block;
        margin-top: 0.5rem;
        color: var(--anthracite-600);
        font-size: 1.5rem;
        line-height: 1.2;
        max-width: 74ch;
      }

      > * {
        max-width: 70rem;
      }
    }
  }
}
