@import '@fastdocs/ui-kit/animations';

.startForm {
  @include fade-in-up;

  ul {
    margin: 1.5rem 0 2.5rem 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
