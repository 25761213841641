@import '@fastdocs/ui-kit/animations';
@import '@fastdocs/ui-kit/media-queries';

.form-stage {
  display: flex;
  flex-direction: column;
  height: 34rem;
  padding: 0 2rem;
  background-color: var(--anthracite-100);
  @include fade-in;

  .content-container {
    margin-top: 12rem;

    @media (min-width: $m) {
      margin-top: 16rem;
    }

    @media (min-width: $l) {
      margin-right: auto;
      margin-left: auto;
      width: 81rem;
      max-width: 81rem;
    }
  }

  .stage-header {
    h4 {
      font-weight: 400;
      max-width: 40rem;

      @media (min-width: $m) {
        max-width: initial;
      }
    }
  }

  .stage-footer {
    display: flex;
    justify-content: space-between;
    font-size: 1.75rem;
    font-weight: 500;
    margin-top: 3.5rem;

    .progress-indicator {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 10rem;

      span {
        margin-right: 1rem;
      }
    }
  }
}
