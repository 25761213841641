@import '@fastdocs/ui-kit/animations';

.further-employments-form {
  @include fade-in;

  .error {
    color: var(--yellow-500);
  }

  .further-employment-fields {
    display: flex;
    flex-direction: column;

    .prepend-input {
      margin-bottom: 2rem;

      label {
        margin-bottom: 0.5rem;
      }
    }
  }
}
