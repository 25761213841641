@import '@fastdocs/ui-kit/animations';
.final-declarations-form {
  @include fade-in-up;

  .checkbox-field {
    margin-bottom: 4rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
