/* autoprefixer grid: autoplace */

.checkbox-field {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  > label {
    text-transform: initial;
    font-size: initial;
    font-weight: initial;
    letter-spacing: initial;
    cursor: pointer;

    > span {
      width: 100%;
      margin-left: 1.5rem;
    }
  }
}
