.text-input-with-option {
  display: flex;
  position: relative;

  input:last-child {
    width: 100%;
    padding-left: 8.5rem;
  }

  .select-container {
    display: flex;
    position: absolute;
    top: 1px;
    left: 1px;
    bottom: 0;
    min-width: 7.5rem;

    select {
      border: none;
      padding: 0.5rem 0.75rem;
      max-height: 4rem;
      font-weight: 600;
      box-shadow: none;
      background: var(--anthracite-200);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: calc(0.5rem - 1px);
      border-bottom-left-radius: calc(0.5rem - 1px);

      &:hover {
        background-color: var(--anthracite-300);
      }
    }

    .icon-container {
      position: absolute;
      right: 0;
      margin-right: 1.5rem;
      top: 24%;
      width: 1.125rem;
      height: 1.125rem;
      pointer-events: none;
    }
  }
}
