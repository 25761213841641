.progressBar {
  position: relative;
  display: block;
  width: 100%;
  height: 0.75rem;
  border-radius: 20rem;
  background-color: var(--anthracite-400);
  overflow: hidden;
  margin-top: 0.25rem;

  .progressIndicator {
    top: 0;
    bottom: 0;
    width: 0;
    height: 100%;
    border-radius: 20rem;
    background-color: var(--blue-400);
    background: linear-gradient(
      90deg,
      var(--blue-200) 0%,
      var(--blue-400) 100%
    );
    transition: all ease 250ms;
  }
}
