@import '@fastdocs/ui-kit/animations';

.overlay {
  @include fade-in;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--ui-overlay);
  z-index: 20;

  article {
    position: relative;
    max-width: 60rem;

    header {
      position: absolute;
      padding: 1rem 3rem;
      border-radius: 10rem;
      top: -2.125rem;
      left: calc(50% - 6.5rem);
      text-align: center;
      width: 7rem;
      background-color: var(--blue-400);
      color: var(--white);
      font-weight: 400;
    }

    main {
      padding: 8rem 6rem 6rem 6rem;
    }

    footer {
      button {
        background-color: var(--white);
        border: none;
        border-radius: 0 0 0.5rem 0.5rem;
        font-size: 2rem;
        color: var(--blue-400);
        height: 6rem;
        padding: 0;
        width: 100%;
        border-top: 1px solid var(--anthracite-200);
        outline: none;
        cursor: pointer;
        transition: all ease 300ms;

        &:focus {
          border-color: var(--blue-400);
          background-color: var(--blue-400);
          color: var(--white);
        }

        &:hover {
          border-color: var(--blue-400);
          background-color: var(--blue-400);
          color: var(--white);
        }
      }
    }
  }

  &.error {
    header {
      background-color: var(--red-500);
    }

    main {
      text-align: center;
    }
  }
}
