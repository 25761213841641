@mixin fade-in {
	@keyframes fade-in {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	opacity: 0;
	animation-name: fade-in;
	animation-duration: 200ms;
	animation-delay: 100ms;
	animation-timing-function: ease-in-out;
	animation-fill-mode: forwards;
	animation-direction: normal;
}
