@import '@fastdocs/ui-kit/media-queries';

.form-header {
  padding: 6rem 3rem;

  @media (min-width: $l) {
    padding: 6rem 6rem 3rem 6rem;
  }

  h1 {
    font-weight: 400;
  }
}
