@import '@fastdocs/ui-kit/media-queries';

.icon-button {
  position: relative;
  height: 2rem;
  width: 2rem;
  padding: 1rem;
  border-radius: 0.5rem;
  transition: background-color ease 250ms;
  background-color: var(--anthracite-100);
  cursor: pointer;

  @media (min-width: $l) {
    &:hover {
      background-color: var(--anthracite-200);

      .tooltip {
        opacity: 1;
      }
    }
  }

  .tooltip {
    opacity: 0;
    position: absolute;
    color: var(--anthracite-700);
    padding: 0.75rem;
    top: 0.25rem;
    border-radius: 0.5rem;
    font-size: 1.75rem;
    font-weight: 400;
    text-align: center;
    pointer-events: none;
    transition: opacity ease 250ms;
    width: max-content;
    pointer-events: none;

    &__right {
      left: 5rem;
    }

    &__left {
      right: 5rem;
    }
  }
}
